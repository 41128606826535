/**
 * Feature component
 */

import * as React from "react";
import { Link } from "gatsby";
import { stripHtml } from "string-strip-html";
import Img from "./img";
import { FaExternalLinkAlt } from "react-icons/fa";
import Content from "./content";

const Features = ({ props, nodes }) => {
  const format =
    props.field_features_format ||
    props.field_post_archive_format ||
    props?.field_people_group_format;
  const sectionHeading =
    props.field_features_heading ||
    props.field_post_archive_heading ||
    props.field_people_group_heading;
  const bgColor =
    props.field_features_bg_color ||
    props.field_post_archive_bg_color ||
    "bg-gray-50";
  const imageAlign = props?.field_features_image_align || "";
  const features = props?.relationships?.field_features || nodes;
  const numCols =
    props?.field_features_column_count ||
      props?.field_people_group_column_count ||
      (features?.length <= 3 ? features?.length : 3);
  const count = props.field_post_count;

  return (
    <>
      <div className={`py-12 md:py-16 ${bgColor}`}>
        <div className="container">
          {sectionHeading && (
            <h2
              className={`${format === "stat" ? "text-center mb-12" : "mb-10"}`}
            >
              {sectionHeading}
            </h2>
          )}
          <div
            className={`grid ${format === "stat"
              ? `${numCols > 1 ? "sm:grid-cols-2" : "max-w-md mx-auto"}  ${numCols === 3
                ? "md:grid-cols-3"
                : numCols === 4
                  ? "lg:grid-cols-4"
                  : ""
              } ${imageAlign === "mx-auto"
                ? "gap-y-8 gap-x-16"
                : "gap-8 md:gap-x-16 xl:gap-y-12 2xl:gap-x-32"
              }`
              : format === "colsRed" || format === "cols"
                ? `${numCols > 1 ? "md:grid-cols-2" : "max-w-md mx-auto"} ${numCols === 3
                  ? "lg:grid-cols-3"
                  : numCols === 4
                    ? "lg:grid-cols-4"
                    : ""
                } ${imageAlign === "mx-auto"
                  ? `gap-10`
                  : `gap-8 md:gap-16 2xl:gap-24`
                }`
                : format === "overlap"
                  ? "lg:grid-cols-2 gap-y-14 gap-x-16 2xl:gap-x-24"
                  : ""
              }`}
          >
            {features?.map((feature, index) => {
              const localFile =
                feature.relationships?.field_feature_image?.relationships
                  ?.field_media_image?.localFile ||
                feature.relationships?.field_image?.relationships
                  ?.field_media_image?.localFile ||
                feature?.relationships?.field_staff_image?.relationships
                  ?.field_media_image?.localFile;
              const imageAlt =
                feature?.relationships?.field_feature_image?.field_media_image
                  ?.alt ||
                feature?.relationships?.field_staff_image?.field_media_image
                  ?.alt;
              const link =
                feature?.relationships?.field_feature_link?.path?.alias ||
                feature?.field_feature_external_link?.uri ||
                feature?.path?.alias;
              const linkIsLocal =
                feature?.relationships?.field_feature_link?.path?.alias
                  ?.length > 0 || feature?.path?.alias?.length > 0;
              const linkLabel = feature?.field_feature_link_label;
              const heading = feature?.field_feature_heading || feature?.title;
              const subheading =
                feature?.field_feature_subheading ||
                feature?.created ||
                feature?.field_staff_title;
              const content =
                feature?.field_feature_content?.processed ||
                feature?.body?.summary ||
                (feature?.body?.processed?.length
                  ? stripHtml(feature.body.processed).result.split(' ').slice(0, 22).join(' ') + "..."
                  : null);
              const fit = feature?.field_feature_image_fit;
              const featureFormat =
                feature?.field_feature_format === "fullWidth"
                  ? "col-span-full"
                  : "";
              const primaryColor =
                feature?.field_feature_primary_color || "red";
              const inverseColor =
                primaryColor === "red" || primaryColor === "gray"
                  ? "white"
                  : "body";
              const imageFocus =
                feature?.field_staff_image_focus ||
                feature?.field_image_focus ||
                "center";
              return (
                <>
                  {!count || index < count ? (
                    <>
                      {format === "colsRed" ? (
                        // Columns
                        <div
                          className={`relative group ${featureFormat}`}
                          key={`feature-${index}`}
                        >
                          {localFile && (
                            <Img
                              localFile={localFile}
                              imageAlt={imageAlt}
                              objectFit={fit}
                              objectPosition={imageFocus}
                              className={`block rounded mb-5 w-auto ${imageAlign} ${imageAlign === "mx-auto" ? "h-12" : "h-28 "
                                } ${link ? "grow" : ""}`}
                            />
                          )}
                          <div>
                            {heading &&
                              (sectionHeading ? (
                                <h3
                                  className={`heading-slim text-${primaryColor} lg:w-5/6`}
                                >
                                  {heading}
                                </h3>
                              ) : (
                                <h2
                                  className={`heading-slim text-${primaryColor} lg:w-5/6`}
                                >
                                  {heading}
                                </h2>
                              ))}
                            {subheading &&
                              (sectionHeading ? (
                                <h4 className={`subheading`}>{subheading}</h4>
                              ) : (
                                <h3 className={`subheading`}>{subheading}</h3>
                              ))}
                            {content && <Content props={content} />}
                            {link && linkIsLocal ? (
                              <Link
                                className={`mt-4 stretched-link ${linkLabel ? `btn btn-${primaryColor}` : ""
                                  }`}
                                to={link}
                                aria-label={!linkLabel ? "More info" : null}
                              >
                                {linkLabel}
                              </Link>
                            ) : link && !linkIsLocal ? (
                              <a
                                className={`mt-4 stretched-link ${linkLabel ? `btn btn-${primaryColor}` : ""
                                  }`}
                                target="_blank"
                                rel="noreferrer"
                                href={link}
                                aria-label={!linkLabel ? "More info" : null}
                              >
                                {linkLabel}
                                {linkLabel && (
                                  <FaExternalLinkAlt className="ml-2" />
                                )}
                              </a>
                            ) : null}
                          </div>
                        </div>
                      ) : format === "cols" ? (
                        // Blog
                        <div className={`relative group ${featureFormat}`}>
                          {localFile && (
                            <Img
                              localFile={localFile}
                              imageAlt={imageAlt}
                              objectFit={fit}
                              objectPosition={imageFocus}
                              className={`block rounded mb-3 sm:mb-5 h-44 w-72 md:h-48 lg:h-44 xl:h-56 md:w-auto ${link ? "grow" : ""
                                }`}
                            />
                          )}
                          <div>
                            {heading &&
                              (sectionHeading ? (
                                <h3 className={``}>{heading}</h3>
                              ) : (
                                <h2 className={`h3`}>{heading}</h2>
                              ))}
                            {subheading &&
                              (sectionHeading ? (
                                <h4
                                  className={`subheading text-${primaryColor}`}
                                >
                                  {subheading}
                                </h4>
                              ) : (
                                <h3
                                  c
                                  className={`subheading text-${primaryColor}`}
                                >
                                  {subheading}
                                </h3>
                              ))}
                            {content && <Content props={content} />}
                            {link && linkIsLocal ? (
                              <Link
                                className={`mt-4 sm:mt-8 stretched-link ${linkLabel ? `btn btn-${primaryColor}` : ""
                                  }`}
                                to={link}
                                aria-label={!linkLabel ? "More info" : null}
                              >
                                {linkLabel}
                              </Link>
                            ) : link && !linkIsLocal ? (
                              <a
                                className={`mt-4 sm:mt-8 stretched-link ${linkLabel ? `btn btn-${primaryColor}` : ""
                                  }`}
                                target="_blank"
                                rel="noreferrer"
                                href={link}
                                aria-label={!linkLabel ? "More info" : null}
                              >
                                {linkLabel}
                                {linkLabel && (
                                  <FaExternalLinkAlt className="ml-2" />
                                )}
                              </a>
                            ) : null}
                          </div>
                        </div>
                      ) : format === "stat" ? (
                        // Statistics
                        <div className={`relative group ${featureFormat}`}>
                          {localFile && (
                            <Img
                              localFile={localFile}
                              imageAlt={imageAlt}
                              className={`block rounded mx-auto w-auto mb-4 ${imageAlign === "mx-auto" ? "h-12" : "h-28 "
                                } ${link ? "grow" : ""}`}
                              objectFit={fit}
                              objectPosition={imageFocus}
                            />
                          )}
                          <div>
                            {heading &&
                              (sectionHeading ? (
                                <h3
                                  className={`font-extralight text-3xl lg:text-4xl xl:text-6xl text-center text-${primaryColor} font-display`}
                                >
                                  {heading}
                                </h3>
                              ) : (
                                <h2
                                  className={`font-extralight text-3xl lg:text-4xl xl:text-6xl text-center text-${primaryColor} font-display`}
                                >
                                  {heading}
                                </h2>
                              ))}
                            {subheading &&
                              (sectionHeading ? (
                                <h4 className="subheading text-center font-normal tracking-wider">
                                  {subheading}
                                </h4>
                              ) : (
                                <h3 className="subheading text-center font-normal tracking-wider">
                                  {subheading}
                                </h3>
                              ))}
                            {content && <Content props={content} />}
                            {link && linkIsLocal ? (
                              <Link
                                className={`mt-4 stretched-link ${linkLabel ? `btn btn-${primaryColor}` : ""
                                  }`}
                                to={link}
                                aria-label={!linkLabel ? "More info" : null}
                              >
                                {linkLabel}
                              </Link>
                            ) : link && !linkIsLocal ? (
                              <a
                                className={`mt-4 stretched-link ${linkLabel ? `btn btn-${primaryColor}` : ""
                                  }`}
                                target="_blank"
                                rel="noreferrer"
                                href={link}
                                aria-label={!linkLabel ? "More info" : null}
                              >
                                {linkLabel}
                                {linkLabel && (
                                  <FaExternalLinkAlt className="ml-2" />
                                )}
                              </a>
                            ) : null}
                          </div>
                        </div>
                      ) : format === "overlap" ? (
                        // Overlapping Image
                        <div
                          className={`${index === 0
                            ? "lg:col-span-2 lg:grid lg:grid-cols-5"
                            : "flex flex-col"
                            } ${featureFormat}`}
                        >
                          {localFile && (
                            <Img
                              localFile={localFile}
                              imageAlt={imageAlt}
                              objectFit={fit}
                              objectPosition={imageFocus}
                              className={`block rounded lg:w-full h-72 md:h-96 mb-4 lg:mb-0 ${index === 0
                                ? "col-span-3 lg:h-full relative"
                                : "lg:h-72"
                                }
                        `}
                            />
                          )}
                          <div
                            className={`${link ? "grow": ""} relative shadow-lg flex-grow  w-11/12 -mt-12 p-8 mx-auto bg-${primaryColor} text-${inverseColor}
                            ${inverseColor === "white"  ? "links-white links-underline" : ""}
                            ${index === 0
                              ? "lg:-ml-12 lg:col-span-2 lg:p-10 lg:relative lg:my-4 lg:w-auto"
                              : ""
                              }`}
                          >
                            {sectionHeading && (
                              <p className="subheading">{subheading}</p>
                            )}
                            {heading &&
                              (sectionHeading ? (
                                <h3 className={`${index === 0 ? "h2" : ""}`}>
                                  {heading}
                                </h3>
                              ) : (
                                <h2 className={`${index === 0 ? "" : "h3"}`}>
                                  {heading}
                                </h2>
                              ))}
                            {content && <Content props={content} />}
                            {link && linkIsLocal ? (
                              <Link
                                className={`mt-2 stretched-link ${linkLabel
                                  ? `subheading text-current hover:text-current stretched-link`
                                  : ""
                                  }`}
                                to={link}
                                aria-label={!linkLabel ? "More info" : null}
                              >
                                {linkLabel}
                              </Link>
                            ) : link && !linkIsLocal ? (
                              <a
                                className={`mt-2 stretched-link ${linkLabel
                                  ? `subheading text-current hover:text-current stretched-link`
                                  : ""
                                  }`}
                                target="_blank"
                                rel="noreferrer"
                                href={link}
                                aria-label={!linkLabel ? "More info" : null}
                              >
                                {linkLabel}
                                {linkLabel && (
                                  <FaExternalLinkAlt className="ml-2" />
                                )}
                              </a>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
