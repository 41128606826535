import React, { useEffect, createRef } from "react"

export default function PolicySection({ props, index }) {
  const policyIdAndOptions = props?.field_policy_id_and_options
  const terms = createRef()

  useEffect(() => {
    fetch(`https://app.termageddon.com/api/policy/${policyIdAndOptions}`)
      .then(res => res.text())
      .then(res => (terms.current.innerHTML = res))
  }, [policyIdAndOptions, terms])

  return (
    <section key={`section-${index}`} className="container py-20">
      <div className="parsed-content max-w-3xl mx-auto">
        <div ref={terms}>  Please wait while the policy is loaded. If it does not load, please <a rel="noreferrer" href={`https://app.termageddon.com/api/policy/${policyIdAndOptions}`} target="_blank">click here</a>.</div>
      </div>
    </section>
  )
}
