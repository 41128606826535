import React from "react"
import parse from "html-react-parser"
import { Disclosure } from "@headlessui/react"
import { FaChevronDown } from "react-icons/fa";
import { kebabCase } from "lodash"

export default function Accordions({ props }) {
  const heading = props?.field_accordions_heading
  const accordions = props?.relationships?.field_accordions
  return (
    <>
      {accordions && (
        <div className="container py-12 md:py-16 max-w-4xl">
          {heading && <h2 id={kebabCase(heading)}>{heading}</h2>}
          <dl
            className={`grid mt-6 space-y-3 divide-y divide-gray-100`}
          >
            {accordions.map((accordion, accordionIndex) => {
              return (
                <>
                  <Disclosure
                    as="div"
                    key={accordionIndex}
                    className="pt-3 -mx-2"
                  >
                    {({ open }) => (
                      <Accordion
                        props={accordion}
                        open={open}
                      />
                    )}
                  </Disclosure>
                </>
              )
            })}
          </dl>
        </div>
      )}
    </>
  )
}
function Accordion({
  props,
  open,
}) {
  const button = props?.field_accordion_button
  const panel = props?.field_accordion_panel?.value
  return (
    <>
      <dt
        className={`inline-flex dt w-full`}
      >
        <Disclosure.Button className="text-left w-full flex justify-between items-baseline rounded transition hover:bg-green-50/60 group px-2 py-3">
          <h3 className="text-lg font-medium leading-snug mb-0">
            {parse(button)}
          </h3>
          <span className="ml-6 flex items-center text-gray-300 group-hover:text-gray-600 print:hidden">
            <FaChevronDown
              className={`${open ? "-rotate-180" : "rotate-0"} text-xl  transform`}
              aria-hidden="true"
            />
          </span>
        </Disclosure.Button>

      </dt>

      <>
        <Disclosure.Panel as="dd" className="mt-2 pl-2 pr-12 pb-6 print:hidden">
          <AccordionContent
            panel={panel}
          />
        </Disclosure.Panel>
        <dd className="hidden print:block">
          <AccordionContent
            panel={panel}
          />
        </dd>
      </>

    </>
  )
}

function AccordionContent({
  panel
}) {
  return (
    <>
      {panel && (
        <div className="parsed-content">{parse(panel)}</div>
      )}
    </>
  )
}
