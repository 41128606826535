/**
 * Testimonial component
 */

import * as React from "react";
import { Link } from "gatsby";
import Slider from "react-slick";
import Img from "./img";
import CfonnIconRound from "./icons/cfonn-icon-round";
import Content from "./content";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/testimonials.css";

const Testimonials = ({ testimonials, bgColor }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <section className={bgColor}>
      <div className="container">
        <div className="sm:mx-4 lg:mx-8 overflow-visible">
          <Slider {...settings}>
            {testimonials?.map((testimonial, index) => {
              return <Testimonial testimonial={testimonial} index={index} />;
            })}
          </Slider>
        </div>
      </div>
    </section>
  );
};

const Testimonial = ({ testimonial, index }) => {
  const heading = testimonial?.title;
  const content = testimonial?.body?.processed;
  const linkedPage = testimonial?.relationships?.field_testimonial_link;
  const link = linkedPage?.path?.alias;
  const linkLabel =
    testimonial?.field_testimonial_link_label || linkedPage?.title;
  const imageAlt =
    testimonial?.relationships?.field_testimonial_image?.field_media_image?.alt ||
    linkedPage?.relationships?.field_image?.field_media_image?.alt;
  const localFile =
    testimonial?.relationships?.field_testimonial_image?.relationships?.field_media_image
      .localFile ||
    linkedPage?.relationships?.field_image?.field_media_image?.localFile;
  const imageFocus = testimonial?.field_testimonial_image_focus || linkedPage?.field_image_focus || "center";
  return (
    <div
      key={`testimonial-${index}`}
      className={`bg-red text-white p-6 sm:px-8 md:px-16 lg:px-20 py-8 sm:py-12 2xl:py-16 sm:mx-4 lg:mx-8 overflow-hidden relative z-10 ${link ? "transition hover:bg-red-600" : ""}`}
    >
      <CfonnIconRound className="w-128 absolute text-gray-50 -bottom-60 -right-24 opacity-30" />
      <div className="sm:flex items-center">
        {localFile && (
          <div className="flex-1">
            <Img
              localFile={localFile}
              imageAlt={imageAlt}
              className="rounded-full w-44 h-44 sm:mr-8 xl:mr-12 overflow-hidden mx-auto"
              imgClassName="rounded-full"
              objectPosition={imageFocus}
            />
          </div>
        )}
        <div>
          {content && (
            <div className="italic text-lg sm:text-xl lg:text-2xl sm:leading-normal mb-4">
              <Content props={content} />
            </div>
          )}
          {heading && (
            <h2 className="mb-0 text-sm font-normal text-right ml-auto max-w-lg">
              - {heading}
            </h2>
          )}
          {link && (
            <div className="text-right mt-4">
              <Link className="subheading text-current hover:text-current stretched-link" to={link}>{linkLabel}</Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Testimonials.defaultProps = {
  bgColor: ``,
};

export default Testimonials;
