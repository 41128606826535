/**
 * PostArchive component
 */

import * as React from "react";
import ArchiveAll from "./archive/archive-all";
import ArchiveArchive from "./archive/archive-archive";
import ArchiveBlog from "./archive/archive-blog";
import ArchiveCaregiverSupportInitiativeUpdates from "./archive/archive-caregiver";
import ArchiveCommunityFund from "./archive/archive-community-fund";
import ArchiveCorporatePhilanthropyPartnersBrief from "./archive/archive-corporate-philanthropy";
import ArchiveGreatGrants from "./archive/archive-great-grants";
import ArchiveDisasterPhilanthropy from "./archive/archive-disaster-philanthropy";
import ArchiveHousing from "./archive/archive-housing";
import ArchiveArchivedNews from "./archive/archive-archived-news";
import ArchiveNewsletter from "./archive/archive-newsletter";
import ArchivePhotosEvents from "./archive/archive-photos-events";
import ArchiveNews from "./archive/archive-news";
import ArchiveProtectYourself from "./archive/archive-protect-yourself";
import ArchiveStories from "./archive/archive-stories";
import ArchiveVideos from "./archive/archive-videos";
import ArchiveWhatsNew from "./archive/archive-whats-new";
import ArchiveProfessionalAdvisors from "./archive/archive-professional-advisors";

const PostArchive = ({ props }) => {
  const category = props?.field_category;
  const heading = props?.field_post_archive_heading;
  const format = props?.field_post_archive_format;
  return (
    <>
      <div className="container">{!format ? <h2>{heading}</h2> : null}</div>
      {category === "allCategories" ? (
        <ArchiveAll props={props} />
      ) : category === "archive" ? (
        <ArchiveArchive props={props} />
      ) : category === "blog" ? (
        <ArchiveBlog props={props} />
      ) : category === "caregiverSupportInitiativeUpdates" ? (
        <ArchiveCaregiverSupportInitiativeUpdates props={props} />
      ) : category === "communityFund" ? (
        <ArchiveCommunityFund props={props} />
      ) : category === "corporatePhilanthropyPartnersBrief" ? (
        <ArchiveCorporatePhilanthropyPartnersBrief props={props} />
      ) : category === "housing" ? (
        <ArchiveHousing props={props} />
      ) : category === "news" ? (
        <ArchiveArchivedNews props={props} />
      ) : category === "newsletter" ? (
        <ArchiveNewsletter props={props} />
      ) : category === "photosEvents" ? (
        <ArchivePhotosEvents props={props} />
      ) : category === "pressReleases" ? (
        <ArchiveNews props={props} />
      ) : category === "protectYourself" ? (
        <ArchiveProtectYourself props={props} />
      ) : category === "stories" ? (
        <ArchiveStories props={props} />
      ) : category === "greatGrants" ? (
        <ArchiveGreatGrants props={props} />
      ) : category === "disasterPhilanthropy" ? (
        <ArchiveDisasterPhilanthropy props={props} />
      ) : category === "professionalAdvisors" ? (
        <ArchiveProfessionalAdvisors props={props} />
      ) : category === "videos" ? (
        <ArchiveVideos props={props} />
      ) : category === "whatsNew" ? (
        <ArchiveWhatsNew props={props} />
      ) : null}
    </>
  );
};

export default PostArchive;
