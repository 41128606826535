/**
 * Scholarships component
 */

import * as React from "react";
import { Link } from "gatsby";

const Scholarships = ({ props }) => {
  let now = new Date();
  const allScholarshipsHeading = props.field_all_scholarships_heading;
  const aScholarshipsHeading = props.field_featured_scholarships_a_he;
  const bScholarshipsHeading = props.field_featured_scholarships_b_he;
  const allScholarships = props?.relationships?.field_scholarships;
  const aScholarships = allScholarships?.filter(
    (scholarship) =>
      new Date(scholarship.field_scholarship_deadline) > now ||
      !scholarship.field_scholarship_deadline
  );
  const bScholarships = allScholarships?.filter(
    (scholarship) => scholarship.field_scholarship_is_featured_b
  );
  const aScholarshipsIsAvailable =
    aScholarships.length !== allScholarships.length;
  now.setDate(now.getDate() - 1);

  return (
    <section className="bg-gray-50 py-10">
      <div className="container">
        <div
          className={`${
            aScholarships?.length &&
            bScholarships?.length &&
            aScholarshipsIsAvailable
              ? "grid lg:grid-cols-2 gap-8"
              : ""
          }`}
        >
          {aScholarshipsIsAvailable && (
            <FundGroup
              scholarships={aScholarships}
              heading={aScholarshipsHeading}
              now={now}
              isAvailable={aScholarshipsIsAvailable}
            />
          )}
          <FundGroup
            scholarships={bScholarships}
            heading={bScholarshipsHeading}
            now={now}
            isAvailable={false}
          />
        </div>
        <div className="my-8">
          <FundGroup
            scholarships={allScholarships}
            heading={allScholarshipsHeading}
            now={now}
          />
        </div>
      </div>
    </section>
  );
};

const FundGroup = ({ scholarships, heading, now, isAvailable }) => {
  // if (isAvailable) {
  //   scholarships.sort(function (a, b) {
  //     return a.field_scholarship_deadline && b.field_scholarship_deadline
  //       ? Date(a.field_scholarship_deadline) -
  //           Date(b.field_scholarship_deadline)
  //       : false;
  //   });
  // }
  return (
    <>
      {scholarships?.length ? (
        <div className="rounded bg-white px-4 pt-3 pb-8 sm:px-8 xl:px-10">
          {heading && (
            <h2 className="h3 text-red sticky top-0 lg:top-20 xl:top-24 pt-3 lg:pt-6 2xl:pt-8 bg-white z-10 pb-4 mb-0 border-b border-gray-100">
              {heading}
            </h2>
          )}
          <ul className="divide-y divide-gray-100">
            {scholarships?.map((scholarship, index) => {
              const scholarshipName = scholarship.field_scholarship_name;

              var scholarshipDeadline = new Date(
                scholarship.field_scholarship_deadline
              );
              const scholarshipIsOpen = scholarshipDeadline
                ? scholarshipDeadline > now
                : true;
              scholarshipDeadline?.setDate(scholarshipDeadline.getDate() + 1);
              scholarshipDeadline = scholarshipDeadline?.toLocaleDateString(
                "en-US"
              );
              const scholarshipDescription =
                scholarship.field_scholarship_description;
              const scholarshipInfoPage =
                scholarship.relationships?.field_scholarship_info_page?.path
                  ?.alias;
              return (
                <>
                  {((isAvailable && scholarshipIsOpen) || !isAvailable) && (
                    <li
                      key={`scholarship-${index}`}
                      className={`flex flex-row py-6 rounded items-center relative ${
                        scholarshipInfoPage ? "hover:bg-green-50" : ""
                      }`}
                    >
                      <div
                        aria-hidden
                        className={`text-6xl ml-2 mr-3 ${
                          scholarshipIsOpen ? "text-green-500" : "text-gray-100"
                        }`}
                      >
                        •
                      </div>
                      <div>
                        <span className="sr-only">
                          {scholarshipIsOpen
                            ? "Scholarship is open"
                            : "Scholarship is closed"}
                        </span>

                        {scholarshipName && (
                          <h3 className="h4 mb-2">{scholarshipName}</h3>
                        )}
                        {scholarshipDescription && (
                          <div className="mb-2">
                            {scholarshipDescription}
                            {scholarshipInfoPage && (
                              <Link
                                className="stretched-link text-red font-bold inline-block"
                                aria-label="More info"
                                to={scholarshipInfoPage}
                              >
                                &nbsp; . . .
                              </Link>
                            )}
                          </div>
                        )}
                        {scholarshipDeadline && (
                          <div className="mb-0">
                            <span className="font-semibold">Deadline:</span>{" "}
                            {scholarshipDeadline}
                          </div>
                        )}
                      </div>
                    </li>
                  )}
                </>
              );
            })}
          </ul>
        </div>
      ) : null}
    </>
  );
};

export default Scholarships;
