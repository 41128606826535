/**
 * PeopleGroup component
 */

import * as React from "react"
import PeopleGroupAdvisoryBoard from "./people-group/people-group-advisory-board"
import PeopleGroupAttorneys from "./people-group/people-group-attorneys"
import PeopleGroupBoardOfficers from "./people-group/people-group-board-officers"
import PeopleGroupBoardTrustees from "./people-group/people-group-board-trustees"
import PeopleGroupCPAs from "./people-group/people-group-cpas"
import PeopleGroupFinancialPlanners from "./people-group/people-group-financial-planners"
import PeopleGroupOtherAdvisors from "./people-group/people-group-other-advisors"
import PeopleGroupStaff from "./people-group/people-group-staff"
import PeopleGroupAuditCommitteeCommunityMembers from "./people-group/people-group-audit-committee-community-members"

const PeopleGroup = ({ props }) => {
  const category = props?.field_department
  const heading = props?.field_people_group_heading
  const format = props?.field_people_group_format
  return (
    <section className="">
      <div className="container">{!format ? <h2>{heading}</h2> : null}</div>
      {category === "staff" ? (
        <PeopleGroupStaff props={props} />
      ) : category === "advisoryBoard" ? (
        <PeopleGroupAdvisoryBoard props={props} />
      ) : category === "boardTrustees" ? (
        <PeopleGroupBoardTrustees props={props} />
      ) : category === "boardOfficers" ? (
        <PeopleGroupBoardOfficers props={props} />
      ) : category === "attorneys" ? (
        <PeopleGroupAttorneys props={props} />
      ) : category === "financialPlanners" ? (
        <PeopleGroupFinancialPlanners props={props} />
      ) : category === "cpas" ? (
        <PeopleGroupCPAs props={props} />
      ) : category === "auditCommitteeCommunityMembers" ? (
        <PeopleGroupAuditCommitteeCommunityMembers props={props} />
      ) : category === "otherAdvisors" ? (
        <PeopleGroupOtherAdvisors props={props} />
      ) : null}
    </section>
  )
}

export default PeopleGroup
