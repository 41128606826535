/**
 * ArchiveAll component
 */

import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Archive from "../archive";

const ArchiveAll = ({ props, location }) => {
  const data = useStaticQuery(graphql`
    {
      allNodeArticle(
        sort: { fields: created, order: DESC }
      ) {
        nodes {
          title
          created(formatString: "MMMM DD, YYYY")
          path {
            alias
          }
          body {
            summary
            processed
          }
          field_image_focus
          relationships {
            field_image {
              ... on media__image {
                field_media_image {
                  alt
                }
                relationships {
                  field_media_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 512)
                      }
                      publicURL
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  return <Archive props={props} nodes={data?.allNodeArticle.nodes} location={location} />;
};

export default ArchiveAll;
