/**
 * PeopleGroup component (used with PeopleGroup Section)
 */

import * as React from "react";
import { Link } from "gatsby";
import Features from "./features";

const PeopleGroup = ({ props, nodes }) => {
  const count = props?.field_post_count;
  const format = props?.field_people_group_format;
  if (props?.field_people_group_sort_) {
    nodes?.sort(function (a, b) {
      if(a.field_person_order && b.field_person_order){
        return a.field_person_order - b.field_person_order;
      }
      if(a.field_person_order){
        return -1
      }
      if(b.field_person_order){
        return 1
      }
      return 0;
    });
  }
  return (
    <>
      {format ? (
        <Features props={props} nodes={nodes} />
      ) : nodes?.length ? (
        <ol className="list-decimal" start="2">
          {nodes.map((article, index) => {
            const title = article.title;
            const date = article.created;
            const link = article.path?.alias;
            return (
              <>
                {!count || index < count ? (
                  <li className="mb-4" key={`archive-${index}`}>
                    <Link to={link}>{title}</Link>
                    <br />
                    <span className="text-xs text-gray-600">{date}</span>
                  </li>
                ) : null}
              </>
            );
          })}
        </ol>
      ) : null}
    </>
  );
};

export default PeopleGroup;
