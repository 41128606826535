import * as React from "react";

function CfonnIcon(props) {
  return (
    <svg
      viewBox="0 0 175 175"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
      {...props}
    >
      <path
        d="M147.159 99.91v-.14c.08-10.41-2.588-20.49-7.745-29.14v-.02c-.889-9.9-4.546-19.55-10.602-27.89l-.08-.11-.12-.05c-5.227-2.1-10.643-3.37-16.109-3.79-3.528-4.19-7.715-7.85-12.472-10.88l-.11-.07-.59-.01c-10.242 0-20.145 2.68-28.66 7.74h-.02c-9.903.89-19.546 4.56-27.89 10.61l-.11.08-.05.12c-2.089 5.23-3.368 10.64-3.788 16.1-4.197 3.53-7.854 7.73-10.872 12.47l-.08.12v.15c-.08 10.4 2.598 20.48 7.745 29.14l.01.01c.879 9.91 4.547 19.55 10.602 27.9l.08.11.12.05c5.227 2.09 10.643 3.37 16.11 3.79 3.527 4.19 7.724 7.85 12.46 10.87l.12.08h.54c10.273 0 20.206-2.68 28.74-7.75h.01c9.914-.89 19.557-4.55 27.891-10.6l.11-.08.05-.12c2.099-5.22 3.378-10.64 3.798-16.11 4.187-3.52 7.844-7.72 10.872-12.48l.04-.1z"
        stroke="currentColor"
        strokeWidth={0.72}
        strokeMiterlimit={10}
      />
      <path
        d="M135.287 112.03c-3.927 3.25-8.174 5.8-12.611 7.57l-.17.06-.08.15c-4.147 7.63-10.493 14.1-18.347 18.72h-.01a51.01 51.01 0 01-5.127.26c-7.375 0-14.63-1.61-20.985-4.66l-.16-.08-.17.04c-4.637 1.15-9.624 1.56-14.66 1.21-3.248-3.92-5.796-8.16-7.575-12.61l-.06-.17-.16-.08c-7.635-4.15-14.1-10.5-18.697-18.35l-.01-.02c-.92-9.16.61-18.18 4.407-26.1l.08-.16-.04-.17c-1.15-4.65-1.559-9.58-1.2-14.67 3.908-3.24 8.155-5.79 12.612-7.58l.17-.06.08-.15c4.147-7.63 10.493-14.1 18.337-18.7h.02c9.114-.92 18.288.65 26.112 4.4l.16.08.17-.04c4.637-1.15 9.634-1.56 14.66-1.21 3.248 3.91 5.796 8.15 7.575 12.61l.06.17.16.08c7.625 4.16 14.09 10.5 18.697 18.35v.03c.919 9.15-.61 18.18-4.407 26.1l-.08.16.04.17c1.159 4.63 1.569 9.57 1.209 14.65z"
        stroke="currentColor"
        strokeWidth={0.72}
        strokeMiterlimit={10}
      />
      <path
        d="M158.521 114.54l.09-.1.03-.14c2.129-12.84.79-25.78-3.867-37.42l-.01-.02v-.04c.849-12.39-1.789-25-7.615-36.45l-.06-.13-.12-.07c-6.046-3.61-12.491-6.25-19.157-7.83-3.547-5.88-8.014-11.22-13.271-15.87l-.099-.09-.14-.03a72.237 72.237 0 00-11.812-.98c-8.834 0-17.688 1.67-25.613 4.84l-.03.01h-.03c-1.489-.1-2.988-.15-4.467-.15-10.932 0-21.994 2.69-31.978 7.77l-.12.06-.07.12c-3.607 6.05-6.245 12.49-7.834 19.16-5.876 3.54-11.212 8.01-15.88 13.28l-.09.1-.03.14c-2.128 12.83-.789 25.77 3.868 37.43l.01.02v.03c-.85 12.38 1.789 24.99 7.615 36.45l.06.13.12.07c6.036 3.61 12.481 6.24 19.147 7.83 3.547 5.89 8.014 11.23 13.28 15.88l.1.09.14.03c3.898.64 7.865.97 11.812.97 8.834 0 17.688-1.67 25.613-4.83l.04-.02h.02c1.439.1 2.938.15 4.447.15 10.932 0 21.995-2.68 31.998-7.76l.12-.06.07-.12c3.607-6.05 6.245-12.5 7.834-19.16 5.886-3.55 11.233-8.02 15.879-13.29z"
        stroke="currentColor"
        strokeWidth={0.72}
        strokeMiterlimit={10}
      />
      <path
        d="M141.803 127.21c-5.496 3.26-11.253 5.6-17.138 6.93l-.16.04-.11.14c-6.626 8.62-15.739 15.38-26.352 19.54l-.04.02h-.03c-11.522-.66-22.385-4.32-31.429-10.57l-.15-.1-.17.02c-1.518.13-3.087.19-4.656.19-4.537 0-9.174-.54-13.78-1.6-3.258-5.5-5.587-11.26-6.916-17.13l-.04-.16-.14-.11c-8.634-6.63-15.39-15.75-19.547-26.37l-.01-.02v-.04c.65-11.51 4.308-22.38 10.553-31.43l.1-.15-.02-.17c-.51-6-.04-12.2 1.4-18.42 5.486-3.26 11.252-5.6 17.138-6.93l.16-.04.11-.14c6.625-8.62 15.738-15.38 26.351-19.54l.03-.01h.04c11.512.66 22.375 4.31 31.429 10.56l.15.1.169-.02c5.986-.51 12.232-.03 18.428 1.4 3.268 5.5 5.596 11.27 6.925 17.15l.04.16.14.11c8.624 6.62 15.379 15.74 19.547 26.36l.01.03v.05c-.65 11.51-4.308 22.38-10.563 31.43l-.1.15.02.17c.529 5.97.06 12.18-1.389 18.4z"
        stroke="currentColor"
        strokeWidth={0.72}
        strokeMiterlimit={10}
      />
      <path
        d="M172.502 87.5l.01-.06c3.468-15.09 2.678-31.14-2.268-46.41l-.04-.14-.11-.08c-6.766-5.64-14.211-10.15-22.135-13.42-3.228-7.96-7.695-15.43-13.281-22.21l-.09-.1-.13-.04A88.958 88.958 0 00106.227.43c-6.355 0-12.63.69-18.657 2.06l-.06.02-.07-.04C81.344 1.07 75 .36 68.573.36a89.252 89.252 0 00-27.54 4.37l-.14.04-.08.11c-5.647 6.78-10.164 14.23-13.422 22.15-7.954 3.22-15.419 7.69-22.214 13.29l-.1.09-.04.13C-.09 55.92-.97 72.14 2.489 87.43l.01.07-.01.07C-.96 102.68-.18 118.73 4.767 133.99l.04.14.11.08c6.755 5.64 14.21 10.15 22.135 13.42 3.218 7.94 7.684 15.42 13.28 22.22l.09.1.13.04c9.174 3.05 18.678 4.6 28.261 4.6 6.356 0 12.631-.69 18.627-2.06l.07-.01.07.01c6.096 1.4 12.442 2.1 18.857 2.1 9.324 0 18.598-1.47 27.551-4.37l.14-.04.08-.11c5.646-6.77 10.153-14.22 13.421-22.15 7.945-3.22 15.419-7.69 22.205-13.29l.1-.09.04-.13c5.126-15.4 6.016-31.62 2.548-46.9l-.02-.05z"
        stroke="currentColor"
        strokeWidth={0.72}
        strokeMiterlimit={10}
      />
      <path
        d="M146.879 147.23c-7.455 2.97-15.049 4.73-22.594 5.23l-.17.01-.13.12c-9.883 9.37-22.475 15.94-36.415 19.01l-.07.02-.07-.02c-14.12-3.07-26.842-9.72-36.795-19.22l-.13-.12-.17-.01c-7.534-.54-15.13-2.34-22.554-5.36-2.978-7.46-4.737-15.06-5.226-22.6l-.01-.17-.12-.13C13.05 114.1 6.475 101.5 3.408 87.57l-.01-.07.02-.07c3.078-14.12 9.723-26.85 19.226-36.8l.12-.13.01-.17c.54-7.54 2.349-15.13 5.357-22.56 7.455-2.97 15.06-4.74 22.594-5.23l.17-.01.13-.12c9.893-9.37 22.485-15.94 36.415-19l.07-.01.07.01c14.12 3.07 26.842 9.71 36.805 19.22l.13.12.169.01c7.525.53 15.11 2.33 22.555 5.35 2.978 7.46 4.737 15.06 5.226 22.61l.01.17.12.13c9.364 9.88 15.939 22.47 18.997 36.41l.02.07-.02.07c-3.058 14.11-9.703 26.84-19.216 36.81l-.12.13-.01.17c-.54 7.52-2.349 15.11-5.367 22.55z"
        stroke="currentColor"
        strokeWidth={0.72}
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default CfonnIcon;