/**
 * (Alternating) Image and Text component
 */

import * as React from "react";
import { Link } from "gatsby";
import Img from "./img";
import Lightbox from "./lightbox";
import { FaExternalLinkAlt } from "react-icons/fa";
import Content from "./content";

const AltImgText = ({ props, index }) => {
  const localFile =
    props?.relationships?.field_image_media?.relationships?.field_media_image
      ?.localFile;
  const imageAlt =
    props?.relationships?.field_image_media?.field_media_image?.alt;
  const link =
    props?.relationships?.field_link?.path?.alias ||
    props?.field_link_external?.uri;
  const linkIsLocal = props?.relationships?.field_link?.path?.alias?.length > 0;
  const linkLabel = props?.field_link_label;
  const imageLink = props?.relationships?.field_image_link?.path?.alias;
  const imageLinkLabel = props?.field_image_link_label;
  const isDonateLink = props?.field_donate_link;
  const heading = props?.field_heading;
  const content = props?.field_content?.processed || props?.body?.processed;
  const primaryColor = props?.field_image_text_primary_color;
  const bgColor = props?.field_image_text_bg_color || "bg-gray-50";
  const format = props?.field_image_text_format;
  const fit = props?.field_image_fit;
  const caption = props?.field_image_caption_content;
  const shadow =
    props?.field_image_shadow && props?.field_image_fit !== "contain"
      ? "shadow-xl"
      : "";
  const focus = props?.field_image_focus;
  const lightbox = props?.field_image_lightbox;
  const flipOrder = props?.field_image_text_order;
  const overflow = props?.field_image_text_overflow
    ? "overflow-x-scroll sm:overflow-x-auto"
    : "";
  const headingAlign = props?.field_image_text_heading_align || "";

  return (
    <div
      className={`${bgColor} ${heading || index === 0 ? "py-12 sm:py-16" : "py-12 sm:pb-16"
        }`}
    >
      <div
        className={`grid grid-cols-1 container gap-8 ${flipOrder ? "grid-flow-row-dense" : ""
          } ${format === "default" && localFile ? "lg:grid-cols-2" : "max-w-4xl"}`}
      >
        <div className={flipOrder ? "lg:col-start-2" : ""}>
          {heading && (
            <h2
              className={`${primaryColor === "red" ? "text-red" : "text-body"
                } ${headingAlign}`}
            >
              {heading}
            </h2>
          )}
          {content && (
            <div className={`mb-4 ${overflow}`}>
              <Content props={content} />
            </div>
          )}
          {(link && linkLabel) || isDonateLink ? (
            <div
              className={`${format !== "default"
                ? "flex flex-col flex-wrap items-center"
                : ""
                }`}
            >
              {linkIsLocal ? (
                <Link
                  className={`btn btn-${primaryColor} ${format !== "default" ? "mx-auto" : ""
                    }`}
                  to={link}
                >
                  {linkLabel}
                </Link>
              ) : link ? (
                <a
                  className={`btn btn-${primaryColor} ${format !== "default" ? "mx-auto" : ""
                    }`}
                  target="_blank"
                  rel="noreferrer"
                  href={link}
                >
                  {linkLabel} <FaExternalLinkAlt className="ml-2" />
                </a>
              ) : isDonateLink ? (
                <a className="btn btn-red" target="_blank" rel="noreferrer" href="https://cfnn.fcsuite.com/erp/donate/list">Give Now <FaExternalLinkAlt className="ml-2" /></a>
              ) : null}
            </div>
          ) : null}
        </div>
        {localFile ? (
          <figure
            className={`${fit === "cover"
              ? "inline-flex flex-row flex-wrap w-full relative"
              : ""
              }`}
          >
            {lightbox ? (
              <>
                <Lightbox>
                  <div className="">
                    <Img
                      localFile={localFile}
                      imageAlt={imageAlt}
                      className={`rounded w-full ${shadow}`}
                      objectPosition={focus}
                      objectFit={fit}
                    />
                  </div>
                </Lightbox>
              </>
            ) : (
              <>
                <Img
                  localFile={localFile}
                  imageAlt={imageAlt}
                  className={`rounded ${shadow}`}
                  objectPosition={focus}
                  objectFit={fit}
                />
              </>
            )}
            {(caption || imageLink) && (
              <figcaption className={`caption ${lightbox ? "" : "w-full"}`}>
                {caption ? caption : null}{" "}
                {imageLink ? (
                  <Link
                    className={`${imageLinkLabel
                      ? `subheading font-semibold inline-block not-italic text-red`
                      : "stretched-link"
                      }`}
                    to={imageLink}
                    aria-label={!imageLinkLabel ? "More info" : null}
                  >
                    {imageLinkLabel}
                  </Link>
                ) : null}
              </figcaption>
            )}
          </figure>
        ) : null}
      </div>
    </div >
  );
};

export default AltImgText;
