/**
 * Banner component
 */

import * as React from "react";
import { Link } from "gatsby";
import parse from "html-react-parser";
import Img from "./img";
import CfonnIcon from "./icons/cfonn-icon";
import Content from "./content";

const Banner = ({ props, bgColor, title, imageFit }) => {
  const localFile =
    props?.relationships?.field_banner_image?.relationships?.field_media_image
      ?.localFile;
  const imageAlt =
    props?.field_banner_image?.field_media_image?.alt;
  const heading = props?.field_banner_heading || title;
  const content = props?.field_banner_content?.processed;
  const link = props?.relationships?.field_banner_link?.path?.alias;
  const label = props?.field_banner_link_label;
  return (
    <section
      className={`${bgColor} print:bg-white ${
        imageFit === "contain" ? "sm:pt-8" : ""
      }`}
    >
      {localFile ? (
        <>
          <div className="relative">
            <Img
              localFile={localFile}
              imageAlt={imageAlt}
              className={`block w-full ${
                imageFit === "cover" ? "h-1/3vh lg:h-1/2vh min-h-80" : "h-auto sm:w-auto max-h-96"
              }`}
              objectFit={imageFit}
              objectPosition={props?.field_banner_image_focus || "unset"}
            />
            {link && label ? (
              <div className="absolute bottom-12 lg:bottom-24 w-full">
                <div className="container flex justify-end">
                  <Link className="ml-auto btn btn-gray" to={link}>
                    {label}
                  </Link>
                </div>
              </div>
            ) : null}
          </div>

          <div className="container">
            <div
              className={`bg-white p-6 sm:py-8 md:px-11 relative shadow-2xl overflow-hidden -mt-8 ${
                imageFit === "cover" ? "lg:-mt-20" : ""
              }`}
            >
              <CfonnIcon className="w-72 absolute text-gray-50 -bottom-28 -left-16 opacity-80" />
              <div className="relative">
                {heading && (
                  <h1 itemProp="headline" className="text-center">
                    {parse(heading)}
                  </h1>
                )}
                {content && (
                  <div className="text-lg">
                    <Content props={content} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>
          <div className="container pt-10">
            <div className="bg-white py-8 px-11 relative shadow-2xl overflow-hidden rounded">
              <CfonnIcon className="w-72 absolute text-gray-50 -bottom-28 -left-16 opacity-80" />
              <div className="relative">
                {heading && <h1 className="text-center">{heading}</h1>}
                {content && (
                  <div className="text-lg">
                    <Content props={content} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

Banner.defaultProps = {
  bgColor: `bg-gray-50`,
  imageFit: `cover`,
};

export default Banner;
