/**
 * PeopleGroupStaff component
 */

import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PeopleGroup from "../people-group";

const PeopleGroupStaff = ({ props, location }) => {
  const data = useStaticQuery(graphql`
    {
      allNodeStaff(
        sort: { fields: title, order: ASC }
        filter: {
          relationships: {
            field_department: { elemMatch: { name: { eq: "Staff" } } }
          }
        }
      ) {
        nodes {
          title
          field_staff_title
          field_person_order
          field_staff_image_focus
          path {
            alias
          }
          relationships {
            field_staff_image {
              ... on media__image {
                field_media_image {
                  alt
                }
                relationships {
                  field_media_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 1536)
                      }
                      publicURL
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  return (
    <PeopleGroup
      props={props}
      nodes={data?.allNodeStaff.nodes}
      location={location}
    />
  );
};

export default PeopleGroupStaff;
