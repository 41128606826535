import React from "react";
import AltImgText from "./alt-img-text";
import Features from "./features";
import BlurbMenu from "./blurb-menu";
import SuccessStory from "./success-story";
import Scholarships from "./scholarships";
import Cards from "./cards";
import PostArchive from "./archive-section";
import PeopleGroup from "./people-group-section";
import Form from "./form";
import Accordions from "./accordions";
import ChartSection from "./chart";
import PolicySection from "./policy-section";

const CustomSections = ({ sections, location }) => {
  if (!sections || !sections.length) {
    return <div></div>;
  }

  return (
    <>
      {sections?.map((section, index) => {
        const fieldGroupName = section?.internal?.type;
        if (fieldGroupName) {
          return (
            <div className="" key={`section-${index}`}>
              {/* {fieldGroupName} */}
              {fieldGroupName.includes("paragraph__image_text") ? (
                <AltImgText props={section} index={index} />
              ) : fieldGroupName.includes("paragraph__feature") ? (
                <Features props={section} />
              ) : fieldGroupName.includes("paragraph__blurb_menu") ? (
                <BlurbMenu props={section} location={location} />
              ) : fieldGroupName.includes("paragraph__success_story") ? (
                <SuccessStory props={section} />
              ) : fieldGroupName.includes("paragraph__scholarships") ? (
                <Scholarships props={section} />
              ) : fieldGroupName.includes("paragraph__cards") ? (
                <Cards props={section} />
              ) : fieldGroupName.includes("paragraph__post_archive") ? (
                <PostArchive props={section} />
              ) : fieldGroupName.includes("paragraph__people_group") ? (
                <PeopleGroup props={section} />
              ) : fieldGroupName.includes("paragraph__form") ? (
                <Form props={section} />
              ) : fieldGroupName.includes("paragraph__accordions") ? (
                <Accordions props={section} />
              ) : fieldGroupName.includes("paragraph__chart") ? (
                <ChartSection props={section} />
              ) : fieldGroupName.includes("paragraph__policy") ? (
                <PolicySection props={section} />
              ) : null}
            </div>
          );
        } else {
          return null;
        }
      })}
    </>
  );
};

export default CustomSections;
