import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Banner from "../components/banner";
import CustomSections from "../components/custom-sections";
import Testimonials from "../components/testimonials";
import Subscribe from "../components/subscribe";

const PageTemplate = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || ``;
  const page = data.nodePage;
  const testimonials = page?.relationships?.node__testimonial;
  const sections = page?.relationships?.field_sections;
  const sectionsLength = sections?.length;
  const bannerBgColor =
    sectionsLength > 0
      ? sections[0].field_features_bg_color ||
      sections[0].field_image_text_bg_color ||
      sections[0].field_post_archive_bg_color ||
      (sections[0].internal?.type === "paragraph__blurb_menu" ||
        sections[0].internal?.type === "paragraph__cards" ||
        sections[0].internal?.type === "paragraph__post_archive" ||
        sections[0].internal?.type === "paragraph__people_group" ||
        sections[0].internal?.type === "paragraph__form" ||
        sections[0].internal?.type === "paragraph__chart"
        ? "bg-gray-50"
        : "bg-white")
      : "bg-gray-50 pb-8";
  const testimonialBgColor = sectionsLength
    ? sections[sectionsLength - 1].field_features_bg_color ||
    sections[sectionsLength - 1].field_image_text_bg_color ||
    sections[sectionsLength - 1].field_post_archive_bg_color ||
    (sections[sectionsLength - 1].internal?.type ===
      "paragraph__blurb_menu" ||
      sections[sectionsLength - 1].internal?.type === "paragraph__cards" ||
      sections[sectionsLength - 1].internal?.type ===
      "paragraph__post_archive" ||
      sections[sectionsLength - 1].internal?.type ===
      "paragraph__people_group" ||
      sections[sectionsLength - 1].internal?.type === "paragraph__form" ||
      sections[sectionsLength - 1].internal?.type === "paragraph__chart"
      ? "bg-gray-50"
      : "bg-white")
    : "bg-gray-50";
  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={page.title}
        // description={page.description || page.excerpt}
        location={location}
      />
      {/* {location} */}
      <article
        className="blog-page"
        itemScope
        itemType="http://schema.org/Article"
      >
        <Banner
          props={page?.relationships?.field_banner}
          bgColor={bannerBgColor}
          title={page?.title}
        />
        {sections && <CustomSections sections={sections} location={location} />}
        {testimonials?.length && (
          <Testimonials
            testimonials={testimonials}
            bgColor={testimonialBgColor}
          />
        )}
        <Subscribe hasTestimonial={testimonials ? true : false} location={location} />
      </article>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query PageById($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    nodePage(id: { eq: $id }) {
      title
      relationships {
        field_banner {
          field_banner_link_label
          relationships {
            field_banner_link {
                path {
                  alias
                }
            }
            field_banner_image {
              field_media_image {
                alt
              }
              relationships {
                field_media_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                    publicURL
                  }
                }
              }
            }
          }
          field_banner_heading
          field_banner_image_focus
          field_banner_content {
            processed
          }
        }
        field_breadcrumbs {
          field_display_breadcrumbs
        }
        field_sections {
          ... on paragraph__image_text {
            field_heading
            field_content {
              processed
            }
            field_link_label
            field_link_external {
              uri
            }
            field_image_focus
            field_image_text_bg_color
            field_image_text_primary_color
            field_image_text_format
            field_image_lightbox
            field_image_fit
            field_image_shadow
            field_image_text_order
            field_image_text_overflow
            field_image_text_heading_align
            field_image_caption_content
            field_donate_link
            field_image_link_label
            relationships {
              field_link {
                ... on node__resource {
                  path {
                    alias
                  }
                }
                ... on node__page {
                  path {
                    alias
                  }
                }
                ... on node__article {
                  path {
                    alias
                  }
                }
              }
              field_image_link {
                ... on node__page {
                  path {
                    alias
                  }
                }
                ... on node__article {
                  path {
                    alias
                  }
                }
                ... on node__resource {
                  path {
                    alias
                  }
                }
              }
              field_image_media {
                field_media_image {
                  alt
                }
                relationships {
                  field_media_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 1536)
                      }
                      publicURL
                    }
                  }
                }
              }
            }
            internal {
              type
            }
          }
          ... on paragraph__features {
            field_features_format
            field_features_heading
            field_features_bg_color
            field_features_image_align
            field_features_column_count
            relationships {
              field_features {
                field_feature_heading
                field_feature_subheading
                field_feature_link_label
                field_feature_external_link {
                  title
                  uri
                }
                field_feature_format
                field_feature_image_fit
                field_feature_content {
                  processed
                }
                field_feature_primary_color
                relationships {
                  field_feature_image {
                    relationships {
                      field_media_image {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(width: 768)
                          }
                          publicURL
                        }
                      }
                    }
                    field_media_image {
                      alt
                    }
                  }
                  field_feature_link {
                    ... on node__resource {
                      path {
                        alias
                      }
                    }
                    ... on node__page {
                      path {
                        alias
                      }
                    }
                    ... on node__article {
                      path {
                        alias
                      }
                    }
                  }
                }
              }
            }
            internal {
              type
            }
          }
          ... on paragraph__blurb_menu {
            field_blurb_content_end {
              processed
            }
            field_blurb_content {
              processed
            }
            field_blurb_heading
            field_blurb_menu
            field_blurb_link_label
            relationships {
              field_blurb_icon {
                field_media_image {
                  alt
                }
                relationships {
                  field_media_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 768)
                      }
                      publicURL
                    }
                  }
                }
              }
              field_blurb_link {
                ... on node__resource {
                  path {
                    alias
                  }
                }
              }
              field_blurb_contact {
                field_contact_email
                field_contact_first_name
                field_contact_name
                field_contact_phone
                field_contact_title
                field_contact_content {
                  processed
                }
                relationships {
                  field_contact_image {
                    field_media_image {
                      alt
                    }
                    relationships {
                      field_media_image {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(width: 768)
                          }
                          publicURL
                        }
                      }
                    }
                  }
                }
              }
            }
            internal {
              type
            }
          }
          ... on paragraph__success_story {
            field_success_story_heading
            internal {
              type
            }
            relationships {
              field_success_story_link {
                path {
                  alias
                }
                title
                internal {
                  type
                }
                body {
                  summary
                }
                relationships {
                  field_image {
                    field_media_image {
                      alt
                    }
                    relationships {
                      field_media_image {
                        localFile {
                          publicURL
                          childImageSharp {
                            gatsbyImageData(width: 768)
                          }
                        }
                      }
                    }
                  }
                }
                field_image_focus
              }
            }
            field_success_story_link_label
          }
          ... on paragraph__scholarships {
            relationships {
              field_scholarships {
                field_scholarship_deadline
                field_scholarship_description
                field_scholarship_is_featured_b
                field_scholarship_name
                relationships {
                  field_scholarship_info_page {
                    path {
                      alias
                    }
                  }
                }
              }
            }
            field_all_scholarships_heading
            field_featured_scholarships_a_he
            field_featured_scholarships_b_he
            internal {
              type
            }
          }
          # ... on paragraph__cards {
          #   relationships {
          #     field_cards {
          #       relationships {
          #         field_card_link {
          #           title
          #           path {
          #             alias
          #           }
          #           relationships {
          #             field_sections {
          #               ... on paragraph__blurb_menu {
          #                 field_blurb_content {
          #                   processed
          #                 }
          #                 relationships {
          #                   field_blurb_icon {
          #                     field_media_image {
          #                       alt
          #                     }
          #                     relationships {
          #                       field_media_image {
          #                         localFile {
          #                           childImageSharp {
          #                             gatsbyImageData(width: 768)
          #                           }
          #                           publicURL
          #                         }
          #                       }
          #                     }
          #                   }
          #                 }
          #                 field_blurb_heading
          #               }
          #             }
          #           }
          #         }
          #       }
          #     }
          #   }
          #   internal {
          #     type
          #   }
          # }
          ... on paragraph__post_archive {
            internal {
              type
            }
            field_category
            field_post_archive_heading
            field_post_archive_format
            field_post_archive_bg_color
            field_post_count
          }
          ... on paragraph__people_group {
            field_people_group_heading
            field_people_group_format
            field_people_group_column_count
            field_department
            field_people_group_sort_
            internal {
              type
            }
          }
          ... on paragraph__form {
            field_form_name
            field_form_heading
            field_form_description {
              processed
            }
            internal {
              type
            }
          }
          ... on paragraph__accordions {
            internal {
              type
            }
            field_accordions_heading
            relationships {
              field_accordions {
                field_accordion_button
                field_accordion_panel {
                  value
                }
              }
            }
          }
          ... on paragraph__chart {
            internal {
              type
            }
            field_chart_title
            field_chart_description {
              value
            }
            field_chart_table {
              value
            }
          }
          ... on paragraph__policy {
            internal {
              type
            }
            field_policy_id_and_options
          }
        }
        node__testimonial {
          title
          body {
            processed
          }
          relationships {
            field_testimonial_image {
              relationships {
                field_media_image {
                  filename
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 768)
                    }
                    publicURL
                  }
                }
              }
              field_media_image {
                alt
              }
            }
            field_testimonial_link {
              path {
                alias
              }
              title
              relationships {
                field_image {
                  field_media_image {
                    alt
                  }
                  relationships {
                    field_media_image {
                      localFile {
                        publicURL
                        childImageSharp {
                          gatsbyImageData(width: 768)
                        }
                      }
                    }
                  }
                }
              }
              field_image_focus
            }
          }
          field_testimonial_link_label
          field_testimonial_image_focus
        }
      }
    }
  }
`;
