/**
 * Form component
 */

import * as React from "react";
import Content from "./content";
import "../styles/form.css";

const Form = ({ props }) => {
  const name = props?.field_form_name;
  const heading = props?.field_form_heading;
  const description = props?.field_form_description?.processed;
  return (
    <section className="bg-gray-50 py-12 sm:py-16">
      <div className="container">
        {heading && <h2>{heading}</h2>}
        {description && (
          <div className={`mb-4`}>
            <Content props={description} />
          </div>
        )}
        {name === "contactUs" ? <FormContactUs props={props} /> : null}
      </div>
    </section>
  );
};

const FormContactUs = ({ props }) => {
  return (
    <form
      name="contact"
      method="POST"
      netlify-honeypot="bot-field"
      data-netlify="true"
      className="max-w-md"
      action="/thank-you"
    >
      <input type="hidden" name="form-name" value="contact" />
      <p class="hidden">
        <label>
          Don’t fill this out if you’re human: <input name="bot-field" />
        </label>
      </p>
      <p>
        <label className="form-label" htmlFor="name">
          Name *
        </label>
        <input
          className="form-field"
          type="text"
          name="name"
          id="name"
          required
        />
      </p>
      <p>
        <label className="form-label" htmlFor="email">
          Email Address *
        </label>
        <input className="form-field" type="email" name="email" required />
      </p>
      <p>
        <label className="form-label" htmlFor="phone">
          Phone Number
        </label>
        <input className="form-field" type="tel" name="phone" id="phone" />
      </p>
      <p>
        <label className="form-label" htmlFor="message">
          Message
        </label>
        <textarea
          className="form-field"
          rows="5"
          name="message"
          id="message"
        ></textarea>
      </p>
      <p>
        <button type="submit" className="btn btn-red">
          Send
        </button>
      </p>
    </form>
  );
};

export default Form;
