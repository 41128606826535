import React, { useEffect, useRef } from 'react'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { parse } from 'html-table-to-json';
import Content from './content';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

ChartJS.defaults.font.size = 16;
ChartJS.defaults.font.family = 'Ideal Sans';
ChartJS.defaults.font.weight = '300';
ChartJS.defaults.color = '#000';

export default function ChartSection({ props }) {
    const table = props?.field_chart_table?.value
    const title = props?.field_chart_title
    const description = props?.field_chart_description?.value
    const json = table ? parse(table) : null;

    const chartRef = useRef();

    const caption = table?.match(/<caption>(.*?)<\/caption>/)[1];

    const labels = json?._headers[0];
    labels?.shift();

    const results = json?._results;

    if (results) {
        results[0].forEach(obj => {
            Object.keys(obj).forEach(key => {
                if (typeof obj[key] === 'string') {
                    if (!obj[key].includes('%')) {
                        delete obj[key];
                    } else {
                        obj[key] = obj[key].replace(/%/g, '');
                    }
                }
            });
        });
    }
    const options = results ? {
        responsive: true,
        maintainAspectRatio: true,
        interaction: {
            intersect: false,
            mode: 'index',
        },
        plugins: {
            legend: {
                position: 'bottom',
                align: 'start',
            },
            title: {
                display: caption ? true : false,
                text: caption,
                align: 'start',
                padding: {
                    bottom: 30,
                },
                font: {
                    weight: '500',
                }
            },

            tooltip: {
                callbacks: {
                    footer: (tooltipItems) => {
                        const sum = (tooltipItems[0].parsed.y - tooltipItems[1].parsed.y).toFixed(2);

                        return '+/- Benchmark: ' + sum + '%';
                    },
                    label: function (context) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += context.parsed.y + '%';
                        }
                        return label;
                    }
                },
                backgroundColor: 'rgba(51, 51, 51, 0.95)',
                padding: 10,
                // titleColor: '#000',
                titleFont: {
                    weight: '500',
                },
                footerFont: {
                    weight: '500',
                },
            }
        },
        layout: {
            padding: 20
        },

        scales: {
            y: {
                ticks: {
                    callback: function (value) {
                        return value + '%';
                    }
                },
                grid: {
                    display: false,
                },
                border: {
                    display: false
                },
                padding: 10,
            },
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false
                },
            },

        }
    } : null;

    const data = (labels && results && results[0] && results[0][0] && results[0][1]) ? {
        labels,
        datasets: [
            {
                type: 'line',
                label: 'Portfolio',
                borderColor: '#CC3333',
                borderWidth: 2,
                fill: false,
                data: results[0][0],
            },
            {
                type: 'line',
                label: 'Benchmark',
                fill: true,
                backgroundColor: 'rgba(62, 214, 188, .2)',
                data: results[0][1],
                borderColor: 'rgba(62, 214, 188, .2)',
                borderWidth: 2,
            },
        ],
    } : null;

    function printHandler(type) {
        for (let id in ChartJS.instances) {
            ChartJS.instances[id].resize(type === "beforeprint" ? 600 : undefined, type === "beforeprint" ? 600 : undefined);
        }
    }

    useEffect(() => {
        window.addEventListener('beforeprint', () => {
            printHandler('beforeprint');
        });
        window.addEventListener('afterprint', () => {
            printHandler('afterprint');
        });
    }, [chartRef]);

    return (
        <section className="bg-gray-50 print:bg-white py-12 sm:pb-16 chart-section">
            <div className="sm:container">
                <div className="grid md:grid-cols-3 bg-white p-8 print:p-0">
                    <div>
                        {title ?
                            <h2 className="h2">{title}</h2>
                            : null}
                        {description ?
                            <div className="mb-8">
                                <Content props={description} />
                            </div>
                            : null}
                    </div>
                    <div className="md:col-span-2 overflow-x-auto -mx-8 sm:mx-0">
                        {table ?
                            <div className="mb-8">
                                <Content props={table} />
                            </div>
                            : null}

                        {data && options ? <div className="bg-green/10 print:bg-transparent">
                            <Line ref={chartRef} type='line' data={data} options={options} />
                        </div> : null}
                    </div>
                </div>
            </div>
        </section>
    )
}

