import * as React from "react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const Lightbox = ({ children }) => {
  return (
    <div className="cursor-pointer w-full">
      <SimpleReactLightbox>
        <SRLWrapper>{children}</SRLWrapper>
      </SimpleReactLightbox>
    </div>
  );
};

export default Lightbox;
