/**
 * Archive component (used with Archive Section)
 */

import * as React from "react";
import { Link } from "gatsby";
import Features from "./features";

const Archive = ({ props, nodes }) => {
  const count = props?.field_post_count;
  const format = props?.field_post_archive_format;
  return (
    <>
      {format ? (
        <Features props={props} nodes={nodes} />
      ) : nodes?.length ? (
        <section className="bg-gray-50 -mt-4 pt-8">
          <ol className="list-none container">
            {nodes.map((article, index) => {
              const title = article.title;
              const date = article.created;
              const link = article.path?.alias;
              return (
                <>
                  {!count || index < count ? (
                    <li className="mb-4" key={`archive-${index}`}>
                      <Link to={link}>{title}</Link>
                      <br />
                      <span className="text-xs text-gray-600">{date}</span>
                    </li>
                  ) : null}
                </>
              );
            })}
          </ol>
        </section>
      ) : null}
    </>
  );
};

export default Archive;
