/**
 * SuccessStory component
 */

import * as React from "react";
import { Link } from "gatsby";
import Img from "./img";
import CfonnIconRound from "./icons/cfonn-icon-round";
import Content from "./content";

const SuccessStory = ({ props }) => {
  const linkLabel = props?.field_success_story_link_label;
  const linkedStory = props?.relationships?.field_success_story_link;
  const heading = props?.field_success_story_heading || linkedStory?.title;
  const link = linkedStory?.path?.alias;
  const content = linkedStory?.body?.summary;
  const imageAlt =
    linkedStory?.relationships?.field_image?.field_media_image
      ?.alt;
  const localFile =
    linkedStory?.relationships?.field_image?.relationships
      ?.field_media_image.localFile;
  const imageFocus = linkedStory?.field_image_focus;
  return (
    <div className="bg-white relative overflow-hidden py-12">
      <div className="container">
        <div className="max-w-4xl mx-auto">
          <CfonnIconRound className="w-144 absolute text-gray-50 -bottom-28 -left-24 opacity-50" />
          <div className="relative sm:flex items-center">
            {localFile && (
              <div className="flex-1">
                <Img
                  localFile={localFile}
                  imageAlt={imageAlt}
                  className="rounded-full w-64 h-64 overflow-hidden mx-auto"
                  imgClassName="rounded-full"
                  objectPosition={imageFocus}
                />
              </div>
            )}

            <div className={`relative sm:ml-8 lg:ml-10 my-8`}>
              {heading && <h2 className="text-red">{heading}</h2>}
              {content && (
                <div className="italic text-xl lg:text-2xl xl:text-3xl sm:leading-tight lg:leading-tight mb-4">
                  <Content props={content} />
                </div>
              )}
              {link && (
                <div className="">
                  <Link className={`btn btn-red mt-6 mb-4`} to={link}>
                    {linkLabel || "Read full story"}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessStory;
