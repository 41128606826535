/**
 * Feature component
 */

import * as React from "react";
import { Link } from "gatsby";
import Img from "./img";
// import Content from "./content"

const Cards = ({ props }) => {
  const cards = props?.relationships?.field_cards;
  return (
    <>
      <div className={`py-12 md:py-16 bg-gray-50`}>
        <div className="container">
          <div
            className={`grid md:grid-cols-2 lg:grid-cols-3 gap-8 xl:gap-x-16 xl:gap-y-12`}
          >
            {cards?.map((card, index) => {
              const sections =
                card.relationships?.field_card_link?.relationships
                  ?.field_sections;
              const blurb = sections ? sections[0] : null;
              const localFile =
                blurb?.relationships?.field_blurb_icon?.relationships
                  ?.field_media_image?.localFile;
              const imageAlt =
                blurb?.relationships?.field_blurb_icon?.field_media_image?.alt;
              const link = card?.relationships?.field_card_link?.path?.alias;
              const heading = card?.relationships?.field_card_link?.title;
              // const content = blurb?.field_blurb_content?.processed;
              return (
                <>
                  <div
                    className={`relative group bg-white rounded ${
                      link ? "grow" : ""
                    } hover:shadow-xl p-4`}
                    key={`card-${index}`}
                  >
                    {blurb?.field_card_content?.processed}

                    <div>
                      {heading && (
                        <h2 className={`heading-slim text-red text-center`}>
                          {heading}
                        </h2>
                      )}
                      {/* {content && (
                          <Content props={content} />
                      )} */}
                      {localFile && (
                        <Img
                          localFile={localFile}
                          imageAlt={imageAlt}
                          objectFit="contain"
                          className={`block rounded my-6 w-auto h-24 lg:h-32 mx-auto `}
                        />
                      )}
                      {link ? (
                        <Link
                          className={`mt-4 stretched-link`}
                          to={link}
                          ariaLabel={`More ${heading} information`}
                        ></Link>
                      ) : null}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Cards;
